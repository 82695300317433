<template>
  <div class="mt-12">
    <v-row v-if="supersaveData">
      <v-col cols="12" md="4">
        <section
          class="white py-10"
          :class="!supersaveData.active ? 'section-padding' : ''"
        >
          <div class="text-center">
            <h1 class="powersave-color__text">
              ₦{{
                supersaveData.amount > 0
                  ? formatAsMoney(supersaveData.amount)
                  : "0.00"
              }}
            </h1>
          </div>
        </section>

        <fj-base-button
          v-if="supersaveData.active == false && supersaveData.amount > 0"
          @click="moveToWithdrawal"
          class="mt-4"
          block
          outlined
        >
          Withdraw Funds
        </fj-base-button>
      </v-col>
      <v-col cols="12" md="4">
        <fj-product-description
          text="Great to join the league of the extraordinary savers. Invest more to earn more."
          color="powersave-color"
          :icon="require('@/assets/images/svg/thumbs.svg')"
        />

        <div class="mt-5">
          <div class="contact-card pa-5" :style="{ height: '100px' }">
            <small class="d-block title">Contact Support</small>
            <small class="d-block sub">Phone Number: 09031341125</small>
            <small class="d-block sub">
              Email:
              <a href="mailto: support@fajomonie.com">
                support@fajomonie.com
              </a>
            </small>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <history></history>
      </v-col>
    </v-row>
    <fj-page-loader v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatDate, formatAsMoney } from "@/utils/helpers";
import history from "@/components/history/history.vue";

export default {
  data() {
    return {
      loading: false,
      activityLogs: [],
    };
  },
  async mounted() {
    await this.saveModule("SUPERSAVE");
    await this.getSupersave();
  },

  components: {
    history,
  },
  computed: {
    ...mapState({
      supersaveData: "Supersave",
    }),
    ...mapState(["userDetails"]),
    ...mapState("history", ["moduleName"]),
  },
  methods: {
    formatDate,
    formatAsMoney,
    ...mapActions("history", ["saveModule"]),
    ...mapActions("powersave", [
      "getPowersave",
      "pausePowersave",
      "restartPowersave",
    ]),
    ...mapActions({
      getSupersave: "supersave/getSupersave"
    }),

    startContribution() {
      this.$router.push({ name: "Pws-Contribution" });
    },

    moveToWalletTopup() {
      this.$store.dispatch('wallet/setWalletDetails', 'POWERSAVE');
      this.$router.push({ name: "Pws-Topup" });
    },

    moveToWithdrawal() {
      this.$router.push({ name: "Sps-Withdrawal" });
    },

    async pause() {
      try {
        this.loading = true;
        await this.pausePowersave({
          sub_id: Number(this.supersaveData.subid),
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },

    async restart() {
      try {
        this.loading = true;
        await this.restartPowersave({
          sub_id: Number(this.supersaveData.subid),
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.powersave-color {
  background: #20316d;

  &__text {
    color: #20316d;
    font-size: 2rem;
  }
}

.activity-icon {
  padding: 9px 12px;
  border-radius: 30px;
  color: #fff;
  font-size: 17px;
  // background: var(--primary);
}

.history {
  height: 72vh;
  overflow: auto;

  @media screen and (max-width: 768px) {
    height: 50vh;
  }
}

.section-padding {
  .text-center {
    display: flex;
    justify-content: center;
  }
}

.contact-card {
  width: 100%;
  position: relative;
  background-color: #fff;

  .title {
    font-size: 1rem;
  }
}
</style>
